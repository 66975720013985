import React from 'react';
import { useEffect, useState } from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Booking = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(JSON.parse(window.localStorage.getItem('show')));
      }, []);

    useEffect(() => {
        window.localStorage.setItem('show', show);
      }, [show]);

    if(show){
        setTimeout(() => {
            setShow(false)
          }, 10000);
    }

    return (
    <div className="min-h-[100vh] bg-slate-100">

        <div>
            <p className="text-center text-2xl md:text-4xl py-5 px-5 font-Raleway">
                Online Booking
            </p>
        </div>

        <form className="flex md:flex-row flex-col md:px-20 py-10 font-Montserrat" action="https://formsubmit.co/hfwc5559@gmail.com" method="POST" onSubmit={() => setShow(true)}>

            <div className='w-full md:px-20 px-5 space-y-5'>

                <div>
                    <label for="first name" className="block mb-2 text-md font-medium text-gray-900">First Name</label>
                    <input type="text" name="First name" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                </div>

                <div>
                    <label for="last name" className="block mb-2 text-md font-medium text-gray-900">Last Name</label>
                    <input type="text" name="Last name" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                </div>

                <div>
                    <label for="last name" className="block mb-2 text-md font-medium text-gray-900">Respond to me via</label>   
                    <div class="mt-5 space-x-7 flex flex-row">

                        <div class="flex items-center gap-x-3">
                            <input id="push-email" name="Respond with" value="email" type="radio" className="h-4 w-4 border-gray-300 text-[#942d64] focus:ring-[#942d64]" required/>
                            <label for="push-email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                        </div>

                        <div class="flex items-center gap-x-3">
                            <input id="push-phone" name="Respond with" value="phone" type="radio" className="h-4 w-4 border-gray-300 text-[#942d64] focus:ring-[#942d64]" required/>
                            <label for="push-phone" className="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                        </div>

                    </div>

                </div>

                <div>
                    <label for="email" className="block mb-2 text-md font-medium text-gray-900">Email Address</label>
                    <input type="email" name="email" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                </div>

                <div>
                    <label for="phone" className="block mb-2 text-md font-medium text-gray-900">Phone Number</label>
                    <input type="text" name="Phone Number" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                </div>

            </div>

            <div className='w-full md:px-20 px-5 space-y-5'>

                <div>
                    <label for="date" className="block mb-2 text-md font-medium text-gray-900">Preferred Day</label>
                    <input type="date" name="Preferred Date" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                </div>        

                <div>
                    <label for="time" className="block mb-2 text-md font-medium text-gray-900">Preferred Time</label>
                    <input type="time" name="Preferred Time" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                </div>

                <div>
                    <label for="last name" className="block mb-2 text-md font-medium text-gray-900">I am a</label>   
                    <div class="mt-5 space-x-7 flex flex-row">

                        <div class="flex items-center gap-x-3">
                            <input id="new" name="I am a" value="New Patient" type="radio" className="h-4 w-4 border-gray-300 text-[#942d64] focus:ring-[#942d64]" required/>
                            <label for="new" className="block text-sm font-medium leading-6 text-gray-900">New Patient</label>
                        </div>

                        <div class="flex items-center gap-x-3">
                            <input id="current" name="I am a" value="Current Patient" type="radio" className="h-4 w-4 border-gray-300 text-[#942d64] focus:ring-[#942d64]" required/>
                            <label for="current" className="block text-sm font-medium leading-6 text-gray-900">Current Patient</label>
                        </div>

                        <div class="flex items-center gap-x-3">
                            <input id="returning" name="I am a" value="Returning Patient" type="radio" className="h-4 w-4 border-gray-300 text-[#942d64] focus:ring-[#942d64]" required/>
                            <label for="returning" className="block text-sm font-medium leading-6 text-gray-900">Returning Patient</label>
                        </div>

                    </div>

                </div>          
                
                <div>
                    <label for="message" className="block mb-2 text-md font-medium text-gray-900">Your message</label>
                    <textarea name="Message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Leave a message..."></textarea>
                </div>

                <input type="hidden" name="_next" value="http://happyfamilywellness.ca"/>
                <input type="hidden" name="_template" value="table"/>
                <input type="hidden" name="_captcha" value="false"/>
                <input type="hidden" name="_autoresponse" value="Thank you for booking an appointment, we will contact you soon!"/>

                <div>
                    <button type="submit" className="text-white bg-[#cc98b4] hover:bg-[#942d64] focus:ring-4 focus:outline-none focus:ring-[#cc98b4] font-medium rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>                    
                </div>

                {show && (
                    <div className="flex p-4 mb-4 text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400">
                        <div className="text-sm font-medium">
                            <p className='text-center'>Message sent, we will get back to you as soon as possible! </p>
                        </div>
                        <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8" onClick={() => setShow(false)}>
                            <FontAwesomeIcon icon={faX} className='self-center w-full'></FontAwesomeIcon>
                        </button>
                    </div>
                )}
            </div>

        </form>
    </div>
)}

