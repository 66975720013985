import { NavBar } from "./components/Navbar";
import { Footer } from "./components/Footer"
import {Home} from "./pages/Home"
import {About} from "./pages/About"
import {Services} from "./pages/Services"
import {PatientsInfo} from "./pages/PatientsInfo"
import {Contact} from "./pages/Contact"
import {Booking} from "./pages/OnlineBooking"
import {BrowserRouter, Route, Routes} from 'react-router-dom';

const App = () => {

  return(
    <BrowserRouter>
      <NavBar/>
        <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/about-us" element={<About />}/>
              <Route path="/new-patients" element={<PatientsInfo />}/>
              <Route path="/services" element={<Services />}/>
              <Route path="/contact" element={<Contact />}/>
              <Route path="/booking" element={<Booking />}/>
        </Routes>
      <Footer/>
    </BrowserRouter>
  )}

export default App;
