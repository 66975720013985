import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const Orthotics = () => {
    return(
        <div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">What Are Orthotics?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>Like the foundation of your home, your feet offer a structural support for your skeleton and spine. Problems in your feet can have a huge impact on your spine’s 
                ability to respond to chiropractic adjustment. At Happy Family Wellness Clinic, we offer high-quality custom foot orthotics to help minimize the effects caused 
                by an unfit ankle and foot function.</p>
            </div>


            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">What Are The Benefits Of Custom Foot Orthotics?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>The root cause of many conditions and symptoms is joint immobility, and poor biomechanics.
                Foot orthotics are crucial for correcting the improper structures and biomechanics of obvious foot problems as well as relieving ankle and knee pain, 
                hip, pelvis and spinal pain. This is accomplished by preventing misalignment of the foot which can hugely impact the way in which all bones move.</p>
                <p>Orthotics are like the insoles that are inside your shoes. When placed inside the shoes, they help control abnormal foot motion. To design orthotics just for you, 
                a detailed physical assessment is completed. Then, a prescription cast for the orthotics is created. Next, the manufacturer designs a computer-generated model from the data, 
                and shapes the orthotics to provide proper control. Additional pads, liners and wedges can be added to make the product completely unique to your needs.</p>
                <p>Even a minor abnormality in your feet can have a huge impact on your joints, causing chronic pain and discomfort if left neglected. These foot orthotics help to enhance 
                performance and comfort for athletes, or individuals with active lifestyles. Diabetics suffering from circulatory problems can also benefit from custom foot orthotics.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">When Do I need Foot Orthotics?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 pb-0 space-y-2">
                <p>If you have been diagnosed by your physician for any of the symptoms mentioned below, custom orthotics can help manage your condition and prevent it from getting worse.</p>
            </div>

            <div className="grid grid-cols-2 text-left text-md font-normal w-full py-3">
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Shin Splints
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Achilles Tendonitis
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Plantar Fasciitis
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Iliotibial Band Syndrome
                        </li>

                    </ul>
                </div>
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Metatarsalgia
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Runner’s Knee
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Morton’s Neuroma
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Sacroiliac Syndrome
                        </li>
                    </ul>
                </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Do Insurance Plans Cover Custom Foot Orthotics?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>Yes. Most Canadians have partial or full coverage for foot orthotics through their Extended Health Benefit plans and Workers Compensation. 
                Check with your provider for more details. We will provide an estimate upon request, simply contact us and the Happy Family Wellness will be happy to cater your needs.</p>
                <p>If you spend most of your time on your feet and suffer from foot, hip, knee or spinal problems, we can provide a significant amount of pain relief and enhance your body’s performance. 
                Our team of professionally trained health specialists are here to provide the natural health solutions and custom foot orthotics you need.</p>
            </div>
        </div>
    )
}